import React from "react";
import Locations from "components/locations";
import Layout from "components/layout";
import SEO from "components/seo";

export default function Location() {
  const description =
    "Looking for translation services in the United States and many other parts of the world? You're in luck because Tomedes is here. 1 Year Guarantee | Rapid Delivery | 24/7 Customer Support";
  const title = "Locations | Tomedes";
  const keywords = "Locations | Tomedes";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug="/locations"
      />
      <Locations />
    </Layout>
  );
}
